import React from "react"
import Header from "../components/header"
import { Container } from "react-bootstrap"
import Footer from "../components/footer"
import { Helmet } from "react-helmet"
import ym from "react-yandex-metrika"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Player } from "video-react"

import mg2Poster from "../images/mg2-poster.png"
import mg2Video from "../images/mg2-video.mp4"

import mg1Poster from "../images/mg1-poster.png"
import mg1Video from "../images/mg1-video.mp4"

import efficiencyPoster from "../images/efficiency-poster.png"
import efficiencyVideo from "../images/efficiency-video.mp4"

class Products extends React.Component {

  componentDidMount() {
    ym("hit", "/products")
  }

  render() {
    let description = "Новинка ассортимента RBM: магнитный фильтр-грязеотделитель MG2. Тройное действие фильтра с двухэтапной магнитной очисткой.\n" +
      "Магнитный фильтр MG1 продлевает срок службы котла отопления."
    return (
      <div>
        <Helmet>
          <title>Продукция - Исполиния</title>
          <meta name="description"
                content={description} />
          <meta property="og:title" content="Продукция" />
          <meta property="og:description"
                content={description} />
        </Helmet>
        <Header />
        <main>

          <Container className="mb-5">
            <div className="row m-auto">
              <h2>Продукция</h2>
              <p>
                Ассортимент RBM очень широк, и данный раздел сайта мы будем наполнять полезными данными о товарах разных
                категорий. Постепенно все необходимые материалы станут здесь доступны.
                <br />
                А сейчас предлагаем Вам ознакомиться с информацией о новейших разработках RBM,
                магнитных фильтрах, которые защищают уязвимые элементы котлов отопления и продлевают срок их службы.
                <br />
                Производство фильтров всегда было сильной стороной RBM, но именно в сегменте магнитной очистки компания
                успела стать признанным лидером отрасли, постоянно развивая существующие продуктовые линейки и предлагая
                инновационные решения. Вот некоторые из них.
              </p>
            </div>
            <div className="row m-auto">
              <div className="col-md-4 m-auto">
                <Link to="../mg2-promo.pdf"><StaticImage placeholder="blurred"
                                                         src="../images/mg2-promo-cover.jpg"
                                                         alt="Листовка нового фильтра MG2"
                /></Link>
              </div>
              <div className="col-md m-auto">
                <Player className="col-sm" poster={mg2Poster} src={mg2Video} />
              </div>
            </div>
            <div className="row m-auto">
              <div className="col-md m-auto">
                <Player className="col-sm" poster={mg1Poster} src={mg1Video} />
              </div>
              <div className="col-md-4 m-auto">
                <Link to="../mg1-promo.pdf"><StaticImage placeholder="blurred"
                                                         src="../images/mg1-promo.jpg"
                                                         alt="Листовка MG1"
                /></Link>
              </div>
            </div>
            <div className="row m-auto">
              <div className="col-md m-auto">
                <Player className="col-sm" poster={efficiencyPoster} src={efficiencyVideo} />
              </div>
            </div>
            <div className="row m-auto">
              <div className="col-md m-auto">
                <Link to="../safecleaner-promo.pdf"><StaticImage placeholder="blurred"
                                                            src="../images/safecleaner-promo.jpg"
                                                            alt="Листовка RBM SafeCleaner2"
                /></Link>
              </div>
              <div className="col-md m-auto">
                <Link to="../magnus-promo.pdf"><StaticImage placeholder="blurred"
                                                            src="../images/magnus-promo.jpg"
                                                            alt="Листовка RBM MagNus2"
                /></Link>
              </div>
              <div className="col-md m-auto">
                <Link to="../selfcleaning-promo.pdf"><StaticImage placeholder="blurred"
                                                            src="../images/selfcleaning-promo.jpg"
                                                            alt="Листовка самопромывные фильтры RBM"
                /></Link>
              </div>
            </div>
            <div className="row m-auto">
              <div className="col-md m-auto">
                <Link to="../rinox-promo.pdf"><StaticImage placeholder="blurred"
                                                                 src="../images/rinox-promo.jpg"
                                                                 alt="Листовка RBM Rinox"
                /></Link>
              </div>
              <div className="col-md m-auto">
                <Link to="../RIS-promo.pdf"><StaticImage placeholder="blurred"
                                                            src="../images/ris-promo.jpg"
                                                            alt="Листовка RBM RIS"
                /></Link>
              </div>
              <div className="col-md m-auto">
                <Link to="../rinox-plus-promo.pdf"><StaticImage placeholder="blurred"
                                                                  src="../images/rinox-plus-promo.jpg"
                                                                  alt="Листовка RBM Rinox Plus и Rinox Plus Smart M"
                /></Link>
              </div>
            </div>
          </Container>
        </main>
        <Footer />

      </div>
    )
  }
}

export default Products